import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="CLI Generator" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "pal-generate"
      }}>{`pal generate`}</h2>
      <p><strong parentName="p">{`Alias: `}</strong>{` `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal g`}</code></p>
      <p>{`Use this command to scaffold all the boring code into your project.`}</p>
      <p>{`Can generate queries, mutations, admin pages, apollo client queries and mutations documents`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#examples"
          }}>{`EXAMPLES`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#output"
          }}>{`output`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#config-file"
          }}>{`Config file (pal.js)`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "#config-examples"
              }}>{`examples`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#type-definitions-for-config-file"
              }}>{`type definitions for config file`}</a></li>
          </ul>
        </li>
      </ul>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal generate `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`MODELS`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`TYPE`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span></code></pre></div>
      <br />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`ARGUMENTS`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Required`}</th>
            <th parentName="tr" {...{
              "align": "center"
            }}>{`Description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`MODELS`}</code></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`no`}</td>
            <td parentName="tr" {...{
              "align": "center"
            }}>{`schema model name to generate files for. You can pass one or more models like User,Post`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`TYPE`}</code></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`no`}</td>
            <td parentName="tr" {...{
              "align": "center"
            }}>{`(crud`}{`|`}{`queries`}{`|`}{`mutations`}{`|`}{`admin`}{`|`}{`graphql) Type of files to generate you can send one or more like queries,mutations`}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <p><strong parentName="p">{`OPTIONS`}</strong></p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`char`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`flag`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-c`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--config=config`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`[default: pal]`}{` You can pass custom config file name`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-m`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--multi`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Add this flag to work with config file as multi schema`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-s`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--schema`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`when you working with multi schema and need to select just one add the schema prop name`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-a`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--autoComplete`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Add this flag with `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`\${ZSH_CUSTOM:=~/.oh-my-zsh/custom}/plugins/`}</code>{` path to generate CLI auto complete for oh-my-zsh`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-h`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--help`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`show CLI help`}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>{`Here's the matrix of which files are generated by which command:`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Type`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Mutations`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Queries`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Client GraphQL`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Admin pages`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`crud`}</code></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`yes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`yes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`queries`}</code></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`yes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`mutations`}</code></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`yes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`admin`}</code></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`yes`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`graphql`}</code></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`yes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
        </tbody>
      </table>
      <h2 {...{
        "id": "examples"
      }}>{`EXAMPLES`}</h2>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`# To generate everything for all models in your schema`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal g

  Note: you need to change User,Post with your schema models

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`# To generate everything for model or more`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal g User,Post

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`# To generate queries for one model or more`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal g User,Post queries

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`# To generate mutations for one model or more`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal g User,Post mutations

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`# To generate admin for one model or more`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal g User,Post admin

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`# To generate graphql for one model or more`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal g User,Post graphql

  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`# To generate queries and mutations for one model or more`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal g User,Post crud`}</code></pre></div>
      <h2 {...{
        "id": "output"
      }}>{`output`}</h2>
      <p>{`Generated code depending on the type of generator`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generator/nexus#output"
          }}>{`nexus`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generator/sdl"
          }}>{`sdl`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generator/graphql-modules"
          }}>{`graphql-modules`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/cli/cnt"
          }}>{`nexus-plugin-prisma`}</a></li>
      </ul>
      <h2 {...{
        "id": "config-file"
      }}>{`Config file`}</h2>
      <p>{`To customize the generator with your needs and project dirs you need to create configurations.`}</p>
      <p>{`Add new file to your project root path with name `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal.js`}</code>{` or `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal.json`}</code></p>
      <h3 {...{
        "id": "config-examples"
      }}>{`Config examples`}</h3>
      <p><strong parentName="p">{`Singel schema`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}>{`module`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`exports `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  backend`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    generator`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'nexus'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    onDelete`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    output`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'src/server/graphql'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  frontend`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    admin`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p><strong parentName="p">{`Multi schemas`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}>{`module`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`exports `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// schema name to add for --schema flag is "schema1"`}</span>{`
  schema1`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// path to schema`}</span>{`
    schema`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./prisma1/schema.prisma'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    backend`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// prisma client name inside the graphql resolver context to generate for CRUD`}</span>{`
      prismaName`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'prisma1'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      generator`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'nexus'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      onDelete`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      output`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'src/server/graphql'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    frontend`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      admin`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  schema2`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    schema`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./prisma2/schema.prisma'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    backend`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      prismaName`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'prisma2'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      generator`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'nexus'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      onDelete`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      output`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'src/server/graphql'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    frontend`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      admin`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <p>{`You can change file name and pass it to your command`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal g -c`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{`config`}</code></pre></div>
      <h2 {...{
        "id": "type-definitions-for-config-file"
      }}>{`type definitions for config file`}</h2>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Config`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// prisma schema path`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  schema`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// add backend generator`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  backend`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// generator type it's require`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`    generator`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` GeneratorsType`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// if you have our admin PrismaTable and need to customize his settings schema path default prisma/`}</span>{`
    adminSettingsPath`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`&`}</span>{` Options`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// add frontend generator`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  frontend`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// generate admin settings and pages`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`    admin`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` AdminPagesOptions `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`boolean`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// generate graphql client documents`}</span>{`
    graphql`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Options `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`GeneratorsType`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'nexus'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'sdl'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'graphql-modules'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'nexus-plugin-prisma'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generator#adminpagesoptions"
          }}>{`AdminPagesOptions`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generator#options-type-definition"
          }}>{`Options`}</a></li>
      </ul>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      